const validateFile = (file) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf', 'application/postscript', 'application/illustrator'];
    const maxSize = 10 * 1024 * 1024; // 5 MB

    if (!allowedTypes.includes(file.type)) {
        throw new Error(`Unsupported file type: ${file.type}`);
    }

    if (file.size > maxSize) {
        throw new Error(`File is too large: ${file.size / (1024 * 1024)} MB (max: 5 MB)`);
    }
};

export { validateFile }